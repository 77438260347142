import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, LinearProgress } from '@mui/material';
import { GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import PublishIcon from '@mui/icons-material/Publish';
import CancelIcon from '@mui/icons-material/Cancel';

import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';
import { usePublishFacebookPost, useRejectFacebookPost } from 'src/api/hooks/useJob';
import { PendingPostsToolbarProps } from './PendingPostsToolbar.types';

export const PendingPostsToolBar: React.FC<PendingPostsToolbarProps> = ({
  selectedFacebookPosts,
  isMainLoading,
  setFBPosts,
}) => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const handleFBPosts = () => {
    setFBPosts(FBPosts => FBPosts.filter(FBPost => !selectedFacebookPosts.includes(FBPost.id)));
  };

  const handleFBPostPublisheSuccessMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t('alerts.socialMedia.publishedFBPosts.success'),
    });
  };

  const handleFBPostPublishErrorMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.socialMedia.publishedFBPosts.error'),
    });
  };

  const [publishFacebookPost, { loading: publishFacebookPostLoading }] = usePublishFacebookPost({
    onComplete: res => {
      if (res) {
        handleFBPosts();
        handleFBPostPublisheSuccessMessage();
      }
    },
    onError: () => {
      handleFBPostPublishErrorMessage();
    },
  });

  const handleFBPostRejectheSuccessMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t('alerts.socialMedia.rejectedFBPosts.success'),
    });
  };

  const handleFBPostRejectErrorMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.socialMedia.rejectedFBPosts.success'),
    });
  };

  const [rejectFacebookPost, { loading: rejectFacebookPostLoading }] = useRejectFacebookPost({
    onComplete: res => {
      if (res) {
        handleFBPosts();
        handleFBPostRejectheSuccessMessage();
      }
    },
    onError: () => {
      handleFBPostRejectErrorMessage();
    },
  });

  const handlePublishingFBPost = () => {
    publishFacebookPost({
      variables: {
        postIds: selectedFacebookPosts,
      },
    });
  };

  const handleRejectingFBPost = () => {
    rejectFacebookPost({
      variables: {
        postIds: selectedFacebookPosts,
      },
    });
  };

  const isLoading = isMainLoading || publishFacebookPostLoading || rejectFacebookPostLoading;

  return (
    <Box mt={1} ml={2}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <GridToolbar />
        </Grid>
        <Grid item textAlign="right" xs={6}>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2} mb={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePublishingFBPost}
                sx={{ width: 250 }}
                disabled={!(selectedFacebookPosts.length > 0) || isLoading}
                startIcon={<PublishIcon />}>
                {t('socialMediaScreen.actions.publishPost')}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleRejectingFBPost}
                sx={{ width: 250 }}
                disabled={!(selectedFacebookPosts.length > 0) || isLoading}
                startIcon={<CancelIcon />}>
                {t('socialMediaScreen.actions.rejectPost')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
