import React, { useState } from 'react';
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { TFunction } from 'react-i18next';

import { FacebookPost } from 'src/DTO/FacebookPost.type';
import { Job } from 'src/DTO/Job.type';
import { RenderCells } from './RenderCells';
import { PendingPostsModal } from './PendingPostsModal';

export const PendingPostsHeaders = (t: TFunction) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return [
    {
      headerName: t('socialMediaScreen.headers.roleNameHU'),
      field: 'job.role.name',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Job>) => params.row.job?.role.name || '-',
      hide: false,
      editable: false,
    },
    {
      headerName: t('socialMediaScreen.headers.roleNameEN'),
      field: 'job.role.localizedData.en.name',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Job>) =>
        params.row.job?.role.localizedData?.en?.name || '-',
      hide: false,
      editable: false,
    },
    {
      headerName: t('socialMediaScreen.headers.text'),
      field: 'postText',
      sorting: false,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <RenderCells params={params} handleOpen={handleOpen} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PendingPostsModal open={open} handleClose={handleClose} params={params} />
      ),
      hide: false,
      editable: true,
    },
    {
      headerName: t('socialMediaScreen.headers.image'),
      field: 'postPictureUrl',
      sorting: false,
      flex: 2,
      renderCell: (params: GridValueGetterParams<FacebookPost>) => {
        return params.row.postPictureUrl ? (
          <img
            src={params.row.postPictureUrl}
            alt="profile"
            onClick={() => window.open(params.row.postPictureUrl, '_blank', 'noopener noreferrer')}
            style={{
              cursor: 'pointer',
              userSelect: 'none',
              width: '200px',
              height: '200px',
            }}
          />
        ) : null;
      },
      hide: false,
      editable: false,
    },
    {
      headerName: t('socialMediaScreen.headers.createdAt'),
      field: 'createdAt',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<FacebookPost>) => {
        const createdAt = new Date(params.row.createdAt).toLocaleString();
        return createdAt;
      },
      hide: false,
      editable: false,
    },
    {
      headerName: t('socialMediaScreen.headers.updatedAt'),
      field: 'updatedAt',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<FacebookPost>) => {
        const updatedAt = new Date(params.row.updatedAt).toLocaleString();
        return updatedAt;
      },
      hide: false,
      editable: false,
    },
  ];
};
