import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Fade,
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Trans, useTranslation } from 'react-i18next';

import { PopUpActionType, PendingModalProps } from './PendingPostsModal.types';

export function PendingPostsModal({ params, open, handleClose }: PendingModalProps) {
  const { field, id, value } = params;

  const { t } = useTranslation();

  const apiRef = useGridApiContext();

  const [inputValue, setInputValue] = useState<string>(value || '');
  const originalValue = useRef<string>(value || '');

  const [confirmationOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [isButtonDisabled, setDisabledSaveButton] = useState<boolean>(true);

  const [actionType, setActionType] = useState<PopUpActionType>(PopUpActionType.CONFIRM_CHANGES);

  const handleClickClose = () => {
    handleClose();
    apiRef.current.setCellMode(id, field, 'view');
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    setInputValue(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue }, event);

    setDisabledSaveButton(
      originalValue.current.replace(/\s+/g, '') === newValue.replace(/\s+/g, ''),
    );
  };

  const handleUndoChanges = () => {
    setInputValue(originalValue.current);
    setDisabledSaveButton(true);
    setConfirmationDialogOpen(false);

    apiRef.current.setEditCellValue({ id, field, value: originalValue.current });
  };

  const openConfirmationDialog = (type: PopUpActionType) => {
    setActionType(type);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmSave = () => {
    apiRef.current.commitCellChange({ id, field });
    setConfirmationDialogOpen(false);
    handleClickClose();
  };

  const handleConfirmCancel = () => {
    setConfirmationDialogOpen(false);
    handleClickClose();
  };

  const getDialogTitle = () => {
    if (actionType === PopUpActionType.CANCEL_CHANGES) {
      return t('socialMediaScreen.modal.cancelChangesConfirm');
    } else if (actionType === PopUpActionType.UNDO_CHANGES) {
      return t('socialMediaScreen.modal.undoChangesConfirm');
    }
    return t('socialMediaScreen.modal.saveChangesConfirm');
  };

  const confirmationButton = (actionType: PopUpActionType) => {
    if (actionType === PopUpActionType.CANCEL_CHANGES) {
      return handleConfirmCancel;
    } else if (actionType === PopUpActionType.UNDO_CHANGES) {
      return handleUndoChanges;
    }
    return handleConfirmSave;
  };

  return (
    <Dialog
      onClose={() => openConfirmationDialog(PopUpActionType.CANCEL_CHANGES)}
      open={open}
      keepMounted
      TransitionComponent={Fade}
      transitionDuration={150}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 3.5,
          padding: 1.8,
          maxHeight: '90vh',
        },
      }}>
      <DialogTitle>
        <Trans>socialMediaScreen.modal.title</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={12}>
            <TextField
              multiline
              placeholder={t('socialMediaScreen.modal.postTextPlaceholder')}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: 1,
                  padding: 2,
                  width: { sm: 500, xs: 350 },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.grey[300],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.primary.main,
                },
              }}
              variant="outlined"
              minRows={4}
              value={inputValue}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginRight: 2 }}>
        <Button
          onClick={() => openConfirmationDialog(PopUpActionType.UNDO_CHANGES)}
          variant="contained"
          disabled={isButtonDisabled}>
          <Trans>socialMediaScreen.modal.actions.undoChanges</Trans>
        </Button>
        <Button
          onClick={() => openConfirmationDialog(PopUpActionType.CANCEL_CHANGES)}
          variant="contained">
          <Trans>generic.cancel</Trans>
        </Button>
        <Button
          onClick={() => openConfirmationDialog(PopUpActionType.CONFIRM_CHANGES)}
          variant="contained"
          autoFocus
          disabled={isButtonDisabled || inputValue === ''}>
          <Trans>generic.save</Trans>
        </Button>
      </DialogActions>

      {confirmationOpen && (
        <Dialog open={confirmationOpen} onClose={() => setConfirmationDialogOpen(false)}>
          <DialogTitle>{getDialogTitle()}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setConfirmationDialogOpen(false)} color="primary" autoFocus>
              <Trans>generic.no</Trans>
            </Button>
            <Button
              onClick={confirmationButton(actionType)}
              color="primary"
              type="submit"
              autoFocus>
              <Trans>generic.yes</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
