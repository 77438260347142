import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetFacebookPosts } from '../api/hooks/useJob';
import { FacebookPostResponse } from '../api/hooks/useJob.types';
import { FacebookPost, PostStatus } from 'src/DTO/FacebookPost.type';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';

export const useFacebookPosts = (postStatus: PostStatus) => {
  const { t } = useTranslation();

  const [FBPosts, setFBPosts] = useState<FacebookPost[]>([]);

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const handleGettingFBPostsErrorMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.socialMedia.fetchFBPosts.error'),
    });
  };

  const [getFacebookPosts, { loading: getFBPostsLoading }] = useGetFacebookPosts({
    onComplete: (res: FacebookPostResponse | undefined) => {
      if (res && Array.isArray(res.posts)) {
        setFBPosts(res.posts);
      }
    },
    onError: () => {
      handleGettingFBPostsErrorMessage();
    },
  });

  useEffect(() => {
    getFacebookPosts({ variables: { status: postStatus } });
  }, [postStatus]);

  return {
    FBPosts,
    getFBPostsLoading,
    setFBPosts,
  };
};
