import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid';

import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useUpdateFacebookPost } from 'src/api/hooks/useJob';
import { FacebookPost, PostStatus } from 'src/DTO/FacebookPost.type';
import { PendingPostsToolBar } from './PendingPostsToolbar';
import { PendingPostsHeaders } from './PendingPosts.header';
import { useFacebookPosts } from 'src/hooks/useGetFacebookPosts';

export const PendingPosts: React.FC = () => {
  const { t } = useTranslation();

  const { FBPosts, getFBPostsLoading, setFBPosts } = useFacebookPosts(PostStatus.Pending);

  const [selectedFacebookPosts, setSelectedFacebookPosts] = useState<string[]>([]);

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const handleFBPostUpdateSuccessMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t('alerts.socialMedia.updateFBPosts.success'),
    });
  };

  const handleFBPostUpdateErrorMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.socialMedia.updateFBPosts.error'),
    });
  };

  const [updateFacebookPost, { loading: updateFacebookPostLoading }] = useUpdateFacebookPost({
    onComplete: _res => {
      handleFBPostUpdateSuccessMessage();
    },
    onError: () => {
      handleFBPostUpdateErrorMessage();
    },
  });

  const updateFBPost = (
    updatedFacebookPost: FacebookPost[],
    newPostId: string,
    newPostText: string,
  ) => {
    setFBPosts(updatedFacebookPost);
    updateFacebookPost({
      variables: {
        updatedFacebookPost: { id: newPostId, postText: newPostText },
      },
    });
  };

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const { value: newPostText, id: postId } = params;

    const updatedPost = [...FBPosts];

    const currentPostIndex = FBPosts.findIndex(post => {
      return post.id === postId;
    });

    const currentPost = FBPosts[currentPostIndex];

    if (newPostText !== currentPost.postText) {
      updatedPost[currentPostIndex] = { ...currentPost, postText: newPostText };
      updateFBPost(updatedPost, String(postId), newPostText);
    }
  };

  const handleCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleOnSelectionModelChange = (selectedFacebookPosts: GridSelectionModel) => {
    setSelectedFacebookPosts(selectedFacebookPosts as string[]);
  };

  const isLoading = getFBPostsLoading || updateFacebookPostLoading;

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        editMode="cell"
        sx={{
          '.MuiDataGrid-cell': { color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' },
        }}
        rowHeight={350}
        components={{
          Toolbar: () => (
            <PendingPostsToolBar
              selectedFacebookPosts={selectedFacebookPosts}
              isMainLoading={isLoading}
              setFBPosts={setFBPosts}
            />
          ),
        }}
        autoPageSize
        rows={FBPosts}
        columns={PendingPostsHeaders(t)}
        disableSelectionOnClick={true}
        onSelectionModelChange={handleOnSelectionModelChange}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={isLoading}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
        checkboxSelection
        onCellEditCommit={handleCellEditCommit}
        onCellEditStop={handleCellEditStop}
      />
    </Grid>
  );
};
