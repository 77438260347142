import { GridRenderEditCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { TFunction } from 'react-i18next';
import { Config } from 'src/api/hooks/useConfigs';

export const ConfiguraionHeaders = (t: TFunction) => {
  return [
    {
      headerName: t('configScreen.headers.id'),
      field: 'config.id',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Config>) => params.row.id || '-',
      hide: false,
      editable: false,
    },
    {
      headerName: t('configScreen.headers.name'),
      field: 'config.name',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Config>) => params.row.name || '-',
      hide: false,
      editable: false,
    },
    {
      headerName: t('configScreen.headers.value'),
      field: 'config.value',
      sorting: false,
      flex: 2,
      valueGetter: (params: GridValueGetterParams<Config>) => params.row.value || '-',
      hide: false,
      editable: true,
    },
  ];
};
