import React from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Interweave } from 'interweave';

import { formatTextWithLineBreaks } from 'src/utils/formatTextWithLineBreaks';
import { RenderCellsProps } from './RenderCells.types';

export function RenderCells({ params, handleOpen }: RenderCellsProps) {
  const apiRef = useGridApiContext();

  const { field, id, value } = params;

  const formattedText = value && formatTextWithLineBreaks(value);

  const handleClickOpen = () => {
    apiRef.current.setCellMode(id, field, 'edit');
    handleOpen();
  };

  return (
    <Box
      onClick={handleClickOpen}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '90%',
        cursor: 'pointer',
        overflow: 'hidden',
        padding: 0,
        paddingRight: 25,
      }}>
      <Tooltip
        placement="left"
        title={<Interweave content={formattedText} allowList={['br']} />}
        disableHoverListener={!formattedText}
        followCursor
        enterDelay={100}
        leaveDelay={300}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 16px',
              backdropFilter: 'blur(15px)',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              maxWidth: '400px',
            },
          },
        }}>
        <Typography
          variant="body1"
          align="center"
          textAlign="left"
          color="white"
          noWrap
          sx={{
            overflow: 'hidden',
            maxWidth: '100%',
            maxHeight: '100%',
            lineHeight: '1-5',
            whiteSpace: 'no-wrap',
          }}>
          <Interweave content={formattedText} allowList={['br']} />
        </Typography>
      </Tooltip>
    </Box>
  );
}
