import { GridRenderEditCellParams } from '@mui/x-data-grid';

export enum PopUpActionType {
  CONFIRM_CHANGES = 'confirm',
  CANCEL_CHANGES = 'cancel',
  UNDO_CHANGES = 'undo',
}

export interface PendingModalProps {
  params: GridRenderEditCellParams;
  open: boolean;
  handleClose: () => void;
}
