import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { DataGrid } from '@mui/x-data-grid';
import { ConfiguraionHeaders } from './Configuration.header';
import { useGetConfigs, Config, useUpdateConfigs } from 'src/api/hooks/useConfigs';
import { ConfigEditModal } from './ConfigUpdateModal';
import { PlusOne } from '@mui/icons-material';

export const ConfigurationScreen: React.FC = () => {
  const [configs, setConfigs] = useState<Config[]>([]);
  const [selectedRow, setSelectedRow] = useState<Config>();
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getConfigValues, { loading }] = useGetConfigs({
    onComplete: res => res && setConfigs(res),
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.configScreen.error'),
      });
    },
  });

  const [updateConfig, { loading: isUpdateLoading }] = useUpdateConfigs({
    onComplete: res => {
      if (res) {
        setConfigs(res);
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.configScreen.success'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.configScreen.error'),
      });
    },
  });

  const handleConfigEdit = (editedConfig: Config) => {
    updateConfig({ variables: editedConfig });
  };

  const handleNewConfig = () => {
    setSelectedRow({
      id: -1,
      name: '',
      value: '',
    });
  };

  useEffect(() => getConfigValues(), []);

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        editMode="cell"
        sx={{
          '.MuiDataGrid-cell': { color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' },
        }}
        rowHeight={250}
        components={{
          Toolbar: () => (
            <Grid item xs={2} mb={2} style={{ padding: 20 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleNewConfig}
                sx={{ width: 250 }}
                startIcon={<PlusOne />}>
                {t('Add new config')}
              </Button>
            </Grid>
          ),
        }}
        autoPageSize
        rows={configs}
        columns={ConfiguraionHeaders(t)}
        disableSelectionOnClick={true}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading || isUpdateLoading}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
        onCellClick={e => setSelectedRow(e.row)}
      />
      {selectedRow && (
        <ConfigEditModal
          {...selectedRow}
          open={!!selectedRow}
          handleClose={() => setSelectedRow(undefined)}
          handleConfigEdit={handleConfigEdit}
        />
      )}
    </Grid>
  );
};
