import React from 'react';
import { Dialog, Grid, DialogContent, TextField, Fade, DialogActions, Button } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Trans } from 'react-i18next';

import { NonPendingPostsProps } from './NonPendingPostsModal.types';

export function NonPendingPostsModal({ params, open, handleClose }: NonPendingPostsProps) {
  const { field, id, value } = params;

  const apiRef = useGridApiContext();

  const handleClickClose = () => {
    apiRef.current.setCellMode(id, field, 'view');
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClickClose}
      open={open}
      keepMounted
      TransitionComponent={Fade}
      transitionDuration={150}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 3.5,
          padding: 1.5,
          maxHeight: '90vh',
        },
      }}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={12}>
            <TextField
              multiline
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: 1,
                  padding: 2,
                  width: { sm: 500, xs: 250 },
                },
                pointerEvents: 'none',
              }}
              variant="outlined"
              minRows={4}
              value={value}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          marginRight: 2,
        }}>
        <Button onClick={handleClickClose} variant="contained" autoFocus>
          <Trans>generic.cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
