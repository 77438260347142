import { Job } from './Job.type';

export enum PostStatus {
  Pending = 'Pending',
  Posted = 'Posted',
  Rejected = 'Rejected',
}

export interface FacebookPost {
  id: string;
  postText?: string;
  job?: Job;
  status: PostStatus;
}
