import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Work,
  Home,
  Person,
  Apartment,
  People,
  Assignment,
  Email,
  ConfirmationNumber,
  AddTwoTone,
  Block,
  VoiceChat,
  Cast,
  Widgets,
  Facebook,
  NotInterested,
  Pending,
  Done,
  Code,
} from '@mui/icons-material';

import { SideDrawer } from './SideDrawer';

export interface NavItem {
  label: string;
  icon: () => ReactNode;
  link?: string;
}

export interface NestedNavItem extends NavItem {
  subsItems?: NavItem[];
}

const Nav: React.FC = () => {
  const { t } = useTranslation();

  const NAV_ITEMS: NestedNavItem[] = [
    { label: t('menu.home'), icon: () => <Home color="primary" />, link: '/home' },
    { label: t('menu.companies'), icon: () => <Apartment color="primary" />, link: '/companies' },
    {
      label: t('menu.workerFunctions'),
      icon: () => <People color="primary" />,
      link: '',
      subsItems: [
        {
          label: t('menu.join'),
          icon: () => <AddTwoTone color="primary" />,
          link: '/join-requests',
        },
        {
          label: t('menu.workerProfiles'),
          icon: () => <People color="primary" />,
          link: '/worker-profile',
        },
        {
          label: t('menu.delete'),
          icon: () => <Block color="primary" />,
          link: '/delete-requests',
        },
      ],
    },
    {
      label: t('menu.managerFunctions'),
      icon: () => <Person color="primary" />,
      link: '',
      subsItems: [
        {
          label: t('menu.managerProfiles'),
          icon: () => <Person color="primary" />,
          link: '/manager-profile',
        },
        {
          label: t('menu.managerInvites'),
          icon: () => <Email color="primary" />,
          link: '/manager-invites',
        },
      ],
    },

    {
      label: t('menu.jobFunctions'),
      icon: () => <Work color="primary" />,
      link: '',
      subsItems: [
        { label: t('menu.jobs'), icon: () => <Work color="primary" />, link: '/jobs' },
        {
          label: t('menu.jobConfirmations'),
          icon: () => <ConfirmationNumber color="primary" />,
          link: '/confirmations',
        },
        {
          label: t('menu.jobApplications'),
          icon: () => <Cast color="primary" />,
          link: '/applications',
        },
      ],
    },
    {
      label: t('menu.financialFunctions'),
      icon: () => <Assignment color="primary" />,
      link: '',
      subsItems: [
        {
          label: t('menu.invoices'),
          icon: () => <Assignment color="primary" />,
          link: '/invoices',
        },
        // {
        //   label: t('menu.invoiceProblems'),
        //   icon: () => <AssignmentLate color="primary" />,
        //   link: '/invoice-problems',
        // },
        // {
        //   label: t('menu.transactions'),
        //   icon: () => <AttachMoney color="primary" />,
        //   link: '/transactions',
        // },
      ],
    },
    // { label: t('menu.logs'), icon: () => <History color="primary" />, link: '/logs' },
    {
      label: t('menu.documents'),
      icon: () => <Widgets color="primary" />,
      link: '/documents',
    },
    {
      label: t('menu.myInterview'),
      icon: () => <VoiceChat color="primary" />,
      link: '/myinterview',
    },
    {
      label: t('menu.socialMedia'),
      icon: () => <Facebook color="primary" />,
      link: '',
      subsItems: [
        {
          label: t('menu.pendingPosts'),
          icon: () => <Pending color="primary" />,
          link: '/pending-posts',
        },
        {
          label: t('menu.publishedPosts'),
          icon: () => <Done color="primary" />,
          link: '/published-posts',
        },
        {
          label: t('menu.rejectedPosts'),
          icon: () => <NotInterested color="primary" />,
          link: '/rejected-posts',
        },
      ],
    },
    {
      label: t('menu.config'),
      icon: () => <Code color="primary" />,
      link: '/configuration',
    },
  ];

  return <SideDrawer navItems={NAV_ITEMS} />;
};

export { Nav };
