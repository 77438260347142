import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { PublishedOrRejectedPostsHeaders } from './PublishedOrRejectedPosts.header';
import { useFacebookPosts } from 'src/hooks/useGetFacebookPosts';
import { PostStatus } from 'src/DTO/FacebookPost.type';

export const RejectedPosts: React.FC = () => {
  const { FBPosts, getFBPostsLoading } = useFacebookPosts(PostStatus.Rejected);

  const { t } = useTranslation();

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        sx={{
          '.MuiDataGrid-cell': { color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' },
        }}
        rowHeight={350}
        autoPageSize
        rows={FBPosts}
        columns={PublishedOrRejectedPostsHeaders(t)}
        disableSelectionOnClick={true}
        loading={getFBPostsLoading}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
