import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Fade,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Config } from 'src/api/hooks/useConfigs';

export enum PopUpActionType {
  CONFIRM_CHANGES = 'confirm',
  CANCEL_CHANGES = 'cancel',
}

export interface EditConfigModalProps extends Config {
  open: boolean;
  handleClose: () => void;
  handleConfigEdit: (e: any) => void;
}

export function ConfigEditModal({
  id,
  name,
  value,
  open,
  handleClose,
  handleConfigEdit,
}: EditConfigModalProps) {
  const { t } = useTranslation();

  const [nameValue, setNameValue] = useState<string>(name || '');
  const [inputValue, setInputValue] = useState<string>(value || '');
  const originalValue = useRef<string>(value || '');

  const [confirmationOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<PopUpActionType>(PopUpActionType.CONFIRM_CHANGES);

  const isButtonDisabled =
    originalValue.current.replace(/\s+/g, '') === inputValue.replace(/\s+/g, '');

  const isNewConfig = id < 0;

  const handleClickClose = () => {
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, type: 'value' | 'name') => {
    const newValue = event.target.value;
    if (type === 'value') {
      setInputValue(newValue);
    } else {
      setNameValue(newValue);
    }
  };

  const openConfirmationDialog = (type: PopUpActionType) => {
    setActionType(type);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmSave = () => {
    setConfirmationDialogOpen(false);
    handleClickClose();
    handleConfigEdit({ id, name: isNewConfig ? nameValue : name, value: inputValue });
  };

  const handleConfirmCancel = () => {
    setConfirmationDialogOpen(false);
    handleClickClose();
  };

  const getDialogTitle = () => {
    if (actionType === PopUpActionType.CANCEL_CHANGES) {
      return t('configScreen.modal.cancelChangesConfirm');
    }
    return t('configScreen.modal.saveChangesConfirm');
  };

  const confirmationButton = (actionType: PopUpActionType) => {
    if (actionType === PopUpActionType.CANCEL_CHANGES) {
      return handleConfirmCancel;
    }
    return handleConfirmSave;
  };

  return (
    <Dialog
      onClose={() => openConfirmationDialog(PopUpActionType.CANCEL_CHANGES)}
      open={open}
      keepMounted
      TransitionComponent={Fade}
      transitionDuration={150}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 3.5,
          padding: 1.8,
          maxHeight: '90vh',
        },
      }}>
      <DialogTitle>
        <Trans>configScreen.modal.title</Trans>
      </DialogTitle>
      <DialogContentText style={{ marginLeft: 20 }}>
        <Trans>configScreen.modal.secondaryTitle</Trans>
      </DialogContentText>
      <DialogContent>
        <Grid container spacing={2}>
          {isNewConfig && (
            <Grid item xs={10} sm={12}>
              <TextField
                placeholder={t('configScreen.modal.placeholder.name')}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: 1,
                    padding: 2,
                    width: { sm: 500, xs: 350 },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme => theme.palette.grey[300],
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme => theme.palette.primary.main,
                  },
                }}
                variant="outlined"
                value={nameValue}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 'name')}
              />
            </Grid>
          )}
          <Grid item xs={10} sm={12}>
            <TextField
              multiline
              placeholder={t('configScreen.modal.placeholder.value')}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: 1,
                  padding: 2,
                  width: { sm: 500, xs: 350 },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.grey[300],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.primary.main,
                },
              }}
              variant="outlined"
              minRows={4}
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 'value')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginRight: 2 }}>
        <Button
          onClick={() => openConfirmationDialog(PopUpActionType.CANCEL_CHANGES)}
          variant="contained">
          <Trans>generic.cancel</Trans>
        </Button>
        <Button
          onClick={() => openConfirmationDialog(PopUpActionType.CONFIRM_CHANGES)}
          variant="contained"
          autoFocus
          disabled={isButtonDisabled || inputValue === ''}>
          <Trans>generic.save</Trans>
        </Button>
      </DialogActions>

      {confirmationOpen && (
        <Dialog open={confirmationOpen} onClose={() => setConfirmationDialogOpen(false)}>
          <DialogTitle>{getDialogTitle()}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setConfirmationDialogOpen(false)} color="primary" autoFocus>
              <Trans>generic.no</Trans>
            </Button>
            <Button
              onClick={confirmationButton(actionType)}
              color="primary"
              type="submit"
              autoFocus>
              <Trans>generic.yes</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
