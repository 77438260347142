import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';

export interface Config {
  id: number;
  name: string;
  value: string;
}

export const useGetConfigs: BaseHookRequest<Config[], null> = params => {
  const [{ getRequest }, state] = useApi(params);
  const getConfigs = () => getRequest<Config[]>('/admin/config');
  return [getConfigs, state];
};

export const useUpdateConfigs: BaseHookRequest<Config[], Config> = params => {
  const [{ postRequest }, state] = useApi(params);
  const updateInterviewQuestions: BaseRequest<Config> = details => {
    return details ? postRequest<Config>('/admin/config/update', details.variables) : undefined;
  };

  return [updateInterviewQuestions, state];
};
